<template>
   <div class="loginPageBox">
    <div id="loginPage">
      <div class="img-c">
        <img src="@/assets/img/login/lgbg3.png" alt="">
      </div>
      <div class="fr login_box">
        <div class="projectName">
          忘记密码
        </div>
        <div class="lp-cont">
            
          <el-form class="loginForm" ref="ruleFormRef" :model="loginForm" label-width="130px" :rules="rules" :label-position="labelPosition">
            <el-form-item prop="cellPhone" label="手机号:">
              <el-input
                v-model="loginForm.cellPhone"
                placeholder="请输入手机号"
                maxlength="11"
                show-word-limit>
              </el-input>
            </el-form-item>
            <el-form-item class="codeItem lg-from" prop="isPass" label="滑动验证:" label-width="230px">
              <drag-verify
                ref="dragVerify"
                v-model:isPassing.sync="loginForm.isPass"
                background = "#fff"
                completedBg="#76c8f6"
                progressBarBg="#76c8f6"
                text="滑动验证"
                successText="验证通过"
                handlerIcon="el-icon-d-arrow-right"
                successIcon="el-icon-check"
                @passcallback="passcallback"
                :width="width"
                :height="height"
                :circle="circle"
                textSize="14px"
                textColor="#666"
              ></drag-verify>
            </el-form-item>
             <el-form-item prop="verificationCode" label="短信验证码:">
              <el-input
                v-model="loginForm.verificationCode"
                placeholder="请输入验证码">
              </el-input>
              <div class="el-button" v-if="bVerification">{{ countDown }}</div>
              <div class="el-button" @click="sendCode" v-else>{{ countDown }}</div>    
            </el-form-item>
            <el-form-item prop="password" label="请设置密码:">
              <el-input
                v-model="loginForm.password"
                placeholder="请输入新密码" show-password>
              </el-input> 
            </el-form-item>
            <el-form-item prop="againPassword" label="请再次输入密码:">
              <el-input
                v-model="loginForm.againPassword"
                placeholder="请再次输入新密码" show-password>
              </el-input> 
            </el-form-item>
            <el-form-item class="lg-btn">
              <button
                type="button"
                class="loginBtn"
                @click="sibmitLogin"
              >确定</button>
            </el-form-item>
            <p style="float:right;margin-top:-10px;color:#76C8F6;font-size:14px;cursor:pointer" @click="gologin">已有账号,去登录</p>
          </el-form>
        </div>
      </div>
  </div>
  <el-dialog v-model="centerDialogVisible" title="" width="20%" center :show-close="false" :modal="false">
      <div style="text-align: center;font-size: 20px;font-weight: bold;color: #000;">修改成功</div>
      <template #footer>
        <span style="color: #999999;">
            {{ countdownSeconds }}秒后将自动返回登录页面
        </span>
      </template>
    </el-dialog>
   </div>

</template> 
<script>
import {
  reactive,
  toRefs,
  onMounted,
  ref
} from "vue";
import dragVerify from "@/components/commons/dragVerify";
import { useRouter } from "vue-router";
import { setStorage } from "@/js/common";
import {F_Update_findPassword,SendVerificationCode} from "@/js/loginApi"
import { defineComponent ,computed } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const ruleFormRef = ref(null)
    const validatePass = (rule, value, callback) => {
    if (!value) {
        callback(new Error('请完成滑动验证'))
    } else {
    callback()
  }
   callback()
}
const password = (rule, value, callback) => {
     if (value != state.loginForm.password) {
        callback(new Error('密码不一致'))
    } else {
    callback()
  }
   callback()
}

 const validatePhone = (rule,value,callback)=>{
      /*console.log(rule);
      console.log(value);
      console.log(callback);*/
      if (!value){
        callback(new Error('手机号不能为空'));
      }
      //使用正则表达式进行验证手机号码
      if (!/^1[3456789]\d{9}$/.test(value)){
        callback(new Error('手机号不正确'));
      }
      callback()
    }
    const state = reactive({
      circle: true,
      centerDialogVisible:false,
      bVerification:false,
      labelPosition:'right',
      timer:null,
      countdownSeconds:'',
      width: 330,
      height: 40,
      loginForm: {
        cellPhone:'',
        verificationCode:'',
        password:'',
        againPassword:'',
        isPass: false,
      },
      rules:{
        cellPhone:[{ validator:validatePhone, trigger: 'blur' }],
        isPass:[{ validator:validatePass, trigger: 'blur' }],
        verificationCode:[{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        password:[{ required: true, message: '密码不能为空', trigger: 'blur' }, { min: 6, max: 16, message: '密码为6-16位', trigger: 'blur' },],
        againPassword:[{ required: true, message: '请再次输入密码', trigger: 'blur' }, { min: 6, max: 16, message: '密码为6-16位', trigger: 'blur' },{ validator:password, trigger: 'blur' }],
      },
      countDown:'发送验证码',
      
      canNext:computed(()=>{
        return (state.loginForm.username != "" &&
        state.loginForm.password != "" &&
        state.isPass)
      })
    });
    // const setToken = ()=>{
    //     setStorage("ftoken", "ftoken");
    // }
    const methods = {
        // 发送验证码
        sendCode:() =>{
            let numCode = 0
            ruleFormRef.value.validateField(['isPass','cellPhone'],(valid) => {
            if (valid) {
               numCode = 1
            } else {
            console.log("未通过");
            }
        });
        if (numCode == 0) {
           let params = {
                telephone:state.loginForm.cellPhone,
                sendType:2,
              }
              SendVerificationCode(params).then(res=>{
                if (res.data.code == 1) {
                   ElMessage({
                  message: res.data.message,
                  type: "success",
                  showClose: true,
                });
                if (state.bVerification) return;
                state.bVerification = true;
                 let countDown = 59;
              const auth_time = setInterval(() => {
                state.countDown = countDown--;
                if (state.countDown <= 0) {
                    state.bVerification = false;
                    state.countDown = "发送验证码";
                    clearInterval(auth_time);
                }
            }, 1000);
                }else{
                  ElMessage({
                  message: res.data.message,
                  type: "error",
                  showClose: true,
                });
                }
              })
             
        }
        },
      passcallback:()=>{
        state.loginForm.isPass = true;
      },
      sibmitLogin: () => {
        
        //通过ref的值触发验证
        ruleFormRef.value.validate((valid) => {
          console.log(valid);
            if (valid) {
              let params = {
                phoneNum:state.loginForm.cellPhone,
                passWord:state.loginForm.againPassword,
                verificationCode:state.loginForm.verificationCode,
              }
              F_Update_findPassword(params).then(res=>{
                  if (res.data.code == 1) {
                    state.centerDialogVisible = true;
                    const TIME_COUNT = 5;
                    if (!state.timer) {
                      state.countdownSeconds = TIME_COUNT;
                      state.timer = setInterval(() => {
                        if (state.countdownSeconds > 1 && state.countdownSeconds <= TIME_COUNT) {
                          state.countdownSeconds--;
                        } else {
                          clearInterval(state.timer); 
                          state.timer = null;
                          router.push("/loginPage");
                        }
                      }, 1000);
                    }
                }else{
                  ElMessage({
                  message: res.data.message,
                  type: "error",
                  showClose: true,
                });
                }
                
              })
            } else {
            console.log("未通过");
            }
        });

      },
      gologin:() =>{
         router.push("/loginPage");
      },
    };
    onMounted(() => {
        store.commit("SetnarbarMenuActive", 1);
        setStorage("narbarMenuActive", 1);
    });

    return {
      ...toRefs(state),
      ...methods,
      ruleFormRef,
      validatePass
    };
  },
  components:{
    dragVerify
  }
});
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.lg-btn {
  width: 365px;
  height: 42px;
  margin: 40px 0 0 0;
  position: relative;
}
.el-button {
    margin-left: 10px;
    border-radius: 5px;
    background: #76C8F6;
    color: #fff;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}
.projectName {
  padding: 20px 0 20px 0;
  position: relative;
  height: 48px;
  font-size: 30px;
  font-weight: 600;
  color: #333333;
  line-height: 48px;
  text-align: center;
}
.loginPageBox{
    width: 100%;
    height: 100%;
    background-color: #F7F8FA;
}
#loginPage {
    position: absolute;
    top: 50%;
    left: 50%;
    width:85%;
    height:90%;
    padding:20px;
    text-align:center;
    transform: translate(-50%, -50%);
  .img-c{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    overflow-y: hidden;
    img{
      top:0;
      left:0;
      width:100%;
      height:100%;
      // object-fit: cover;
    }
  }

  .login_box {
    width: 585px;
    height:505px;
    position:absolute;
    top:40%;
    right:5%;
    transform: translate(0,-50%);
    // background: #fff;
  }

  .lp-c {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 99;
  }

  .lp-cont {
    position: relative;
    padding: 0 60px;
  }
  .lg-logo {
    position: absolute;
    top: -15%;
    left: 50%;
    transform: translate(-50%, -15%);
  }
  .lg-from {
    display: flex;
    justify-content: center;
  }
  .lg-from img {
    position: absolute;
    top: 11px;
    left: 20px;
    width: 22px;
    height: 22px;
    object-fit: contain;
  }

  .el-form-item {
    margin-bottom: 20px;
    &.codeItem {
      width: 365px;
      position: relative;
      height: 40px;
    }
    &.spwd {
      margin: 10px 0 15px;
    }
  }
  /* 验证码 */
  .codeItem {
    position: relative;
  }
  .emCode {
    box-sizing: border-box;
    position: absolute;
    top: 11px;
    left: 80%;
    margin: 0 0 0 -132px;
    width: 106px;
    height: 37px;
    box-sizing: border-box;
    background: #0a2138;
    cursor: pointer;
    .s-canvas {
      height: 100%;
      canvas {
        height: 100%;
        width: 100%;
        opacity: 1;
      }
    }
  }
  /* 登录按钮 */
  .loginBtn {
    width: 340px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    line-height: 42px;
    text-align: center;
    background: #76C8F6;
    color: #fff;
    border: none;
    border-radius: 20px;
    &.gray {
      background: #666;
      color: #cecece;
      cursor: default;
    }
  }
  :deep(.login_box){
    .el-input{
      // background: #fff;
    }
    .el-checkbox__inner {
      border: 1px solid #6fe9ef;
      background-color: transparent;
    }
    .el-input--prefix {
    }
    .el-input--prefix .el-input__inner {
      padding-left: 70px;
      background-size: 100% 100%;
      height: 50px;
      border: none;
      margin: -10px 0;
      width: 410px;
      opacity: 0.6;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
    span.el-input__prefix {
      transform: translate(0, -50%);
      width: 25px;
      height: 25px;
      left: 35px;
      top: 50%;
    }
    .el-form-item__content {
      display: flex;
      justify-content: center;
      width: 100%;
      line-height: 60px;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #16d0ff;
    }
    .el-input__inner {
      color: #666;
      background: #fff;
    //   padding: 0 50px;
      border-radius: 2px;
      border: 1px solid #E1E1E1;
      border-radius: 50px;
    }

  }
}
</style>